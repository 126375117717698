import React from 'react'
import { Link } from 'gatsby'

import { ArrowRight, Terminal } from 'phosphor-react'

import { Banner } from '../Banner'

export const EngineersBanner = () => (
  <Banner
    outerClassName="max-w-lg mb-12 mt-6 mx-auto shadow-solid md:mt-10"
    innerClassName="flex items-center text-sm flex items-center px-6 py-4"
  >
    <Terminal size={24} className="mr-4 flex-shrink-0" />
    <div>
      We’re building a whiteboard for software engineers.{'  '}
      <Link
        to="/blog/2021/whiteboard-for-software-engineers"
        className="hover:text-blue-500 text-blue-600 inline-flex items-center space-x-1"
      >
        <span>Read about how</span>
        <ArrowRight />
      </Link>
    </div>
  </Banner>
)
