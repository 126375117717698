import React from 'react'
import { graphql, Link } from 'gatsby'
import { DateTime } from 'luxon'
import {
  ArrowUpRight,
  CalendarBlank,
  LinkedinLogo,
  TwitterLogo,
  FacebookLogo,
  ClockCounterClockwise,
  ArrowRight,
} from 'phosphor-react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { useAuthors } from '../hooks/useAuthors'
import { BasicLayout } from '../layouts/basic'
import { SEOMeta } from '../components/SEOMeta'
import { Banner } from '../components/Banner'
import { MDXContent } from '../components/MDXContent'
import { Avatar } from '../components/blog/Avatar'
import { EngineersBanner } from '../components/blog/EngineersBanner'

const NEW_QUALDESK_LAUNCH_DATE = DateTime.fromObject({
  year: 2020,
  month: 11,
  day: 26,
})

const BlogPost = ({ location, data: { mdx: post } }) => {
  const { siteUrl } = useSiteMetadata()
  const authors = useAuthors()
  const permalink = `${siteUrl}${location.pathname}`
  const publicationDate = DateTime.fromFormat(
    post.frontmatter.date,
    'd MMM yyyy'
  )

  const isForEngineers = post.frontmatter.audiences?.includes('engineers')

  const isOld = publicationDate < NEW_QUALDESK_LAUNCH_DATE

  return (
    <BasicLayout>
      <SEOMeta
        title={post.frontmatter.title}
        image={post.frontmatter.featuredimage?.publicURL}
        description={post.frontmatter.description}
        path={location.pathname}
      />
      <article className="mx-auto p-6 max-w-3xl lg:max-w-4xl">
        <header className="md:shadow-solid md:p-8 md:bg-white md:border md:border-border-weak-light md:rounded-2xl lg:mt-6 lg:p-10">
          <div className="mx-auto max-w-xl lg:max-w-2xl">
            <h1 className="text-text-primary-light text-3xl font-extrabold tracking-tight leading-tight sm:text-4xl lg:text-5xl">
              {post.frontmatter.title}
            </h1>
            <div className="flex items-center mt-4 text-text-secondary-light text-sm space-x-1">
              <CalendarBlank className="flex-shrink-0" />
              <time>{post.frontmatter.date}</time>
            </div>
            <ul className="mt-6 space-y-4 sm:flex sm:space-x-10 sm:space-y-0 md:pt-6 md:border-t md:border-border-weak-light">
              {post.frontmatter.authors
                .map((id) => authors[id])
                .map((author) => (
                  <li key={author.name} className="flex items-center space-x-4">
                    <Avatar author={author} />
                    <div>
                      <div className="text-text-primary-light text-sm">
                        {author.name}
                      </div>
                      <div className="text-text-secondary-light text-xs">
                        {author.title}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </header>
        <div className="mt-10 mx-auto max-w-xl text-text-primary-light text-sm md:mt-16 lg:max-w-2xl">
          {isOld && (
            <Banner
              outerClassName="max-w-lg mb-12 mt-6 mx-auto shadow-solid md:mt-10"
              innerClassName="flex items-center text-sm flex items-center px-6 py-4"
            >
              <ClockCounterClockwise size={24} className="mr-4 flex-shrink-0" />
              <div>
                This was published before our pivot.{'  '}
                <Link
                  to="/blog/2020/the-new-qualdesk/"
                  className="hover:text-blue-500 text-blue-600 inline-flex items-center space-x-1"
                >
                  <span>Read our announcement</span>
                  <ArrowRight />
                </Link>
              </div>
            </Banner>
          )}
          {isForEngineers && <EngineersBanner />}
          <div>
            <MDXContent>{post.body}</MDXContent>
          </div>
          {isForEngineers && <EngineersBanner />}
        </div>
        <footer className="mx-auto max-w-xl lg:max-w-2xl">
          <a
            className="shadow-solid-sm flex items-center justify-between mt-8 p-4 h-14 text-white text-sm leading-tight active:bg-button-primary-active-light hover:bg-button-primary-hovered-light bg-button-primary-light border border-transparent rounded-lg space-x-2 sm:max-w-max"
            href={process.env.SIGNUP_URL}
          >
            <span className="-mt-0.5">Sign up for free</span>
            <ArrowUpRight className="relative left-0.5" size={20} />
          </a>
          <h2 className="mt-16 text-text-primary-light text-xl font-extrabold tracking-tight leading-tight">
            Share this post
          </h2>
          <ul className="flex mt-4 text-text-primary-light space-x-4">
            <li>
              <a
                className="flex items-center justify-center w-12 h-12 hover:bg-white border border-border-normal-light rounded-lg sm:px-4 sm:w-auto sm:space-x-2"
                href={`https://www.linkedin.com/cws/share?url=${permalink}`}
              >
                <span className="text-sm sr-only sm:-mt-0.5 sm:not-sr-only">
                  LinkedIn
                </span>
                <LinkedinLogo
                  className="sm:relative sm:left-0.5 w-6 h-6 sm:w-5 sm:h-5"
                  weight="light"
                />
              </a>
            </li>
            <li>
              <a
                className="flex items-center justify-center w-12 h-12 hover:bg-white border border-border-normal-light rounded-lg sm:px-4 sm:w-auto sm:space-x-2"
                href={`https://twitter.com/intent/tweet?text=${permalink}`}
              >
                <span className="text-sm sr-only sm:-mt-0.5 sm:not-sr-only">
                  Twitter
                </span>
                <TwitterLogo
                  className="sm:relative sm:left-0.5 w-6 h-6 sm:w-5 sm:h-5"
                  weight="light"
                />
              </a>
            </li>
            <li>
              <a
                className="flex items-center justify-center w-12 h-12 hover:bg-white border border-border-normal-light rounded-lg sm:px-4 sm:w-auto sm:space-x-2"
                href={`https://www.facebook.com/sharer/sharer.php?u=${permalink}`}
              >
                <span className="text-sm sr-only sm:-mt-0.5 sm:not-sr-only">
                  Facebook
                </span>
                <FacebookLogo
                  className="sm:relative sm:left-0.5 w-6 h-6 sm:w-5 sm:h-5"
                  weight="light"
                />
              </a>
            </li>
          </ul>
        </footer>
      </article>
    </BasicLayout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug }, source: { eq: "blog" } }) {
      body
      frontmatter {
        title
        description
        featuredimage {
          publicURL
        }
        authors
        audiences
        date(formatString: "DD MMM YYYY")
      }
    }
  }
`

export default BlogPost
